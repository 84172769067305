import { useAuth0 } from '@auth0/auth0-react';
import Head from 'next/head';

import { Button } from '@relationalai/ui';

import { AuthErrorPanel, AuthPanel } from '../components/auth/AuthPanel';
import { ALLOWED_ACCOUNTS_KEY, RaiUser } from '../components/auth/raiUser';

export default function Login() {
  const { user, error, loginWithRedirect } = useAuth0<RaiUser>();

  if (error) {
    return <AuthErrorPanel error={error} />;
  }

  if (user) {
    const accounts = user[ALLOWED_ACCOUNTS_KEY];

    if (!accounts) {
      return <AuthErrorPanel error={{ message: 'RAI Authentication Error' }} />;
    }
  }

  return (
    <AuthPanel>
      <Head>
        <title>Authentication | Relational AI Console</title>
      </Head>
      <div className='flex justify-center'>
        <Button
          size='xl'
          data-testid='login-button'
          onClick={() => loginWithRedirect()}
        >
          Login
        </Button>
      </div>
    </AuthPanel>
  );
}
